import "./ScrollUp.css";
import {Button} from "react-bootstrap";
import arrow_up from "../../images/arrow-up.svg";

const ScrollUp = () => {
  const onScrollTop = (e) => {
    e.preventDefault();
    window.scrollTo({top: 0, behavior: "smooth"});
  }

  return (
    <Button onClick={onScrollTop} className="scroll-up-btn">
      <img src={arrow_up} alt=""/>
    </Button>
  );
}

export default ScrollUp;