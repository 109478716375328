import "./HomeFooter.css";
import Block from "../../images/block.svg";
import Web from "../../images/web.svg";
import Mail from "../../images/mail.svg";
import ScrollUp from "../ScrollUp/ScrollUp";

const FooterCom = ({logo, text}) => {
  return (
    <p className="com-name">
      <img src={logo} alt="" className="mx-3"/> {text}
    </p>
  );
}

const FooterItem = ({logo, text, href, className = ""}) => {
  return (
    <p className={className}>
      <img src={logo} alt="" className="mx-3"/> <a href={href}>{text}</a>
    </p>
  );
}

const HomeFooter = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer-div App-wrap">
      <FooterCom logo={Block} text="Site 3 Productions"/>
      <FooterItem logo={Web} href="https://site3productions.com" text="site3productions.com"/>
      <FooterItem logo={Mail} href="mailto:contact@site3productions.com" text="contact@site3productions.com"/>
      <div className="d-flex justify-content-between">
        <p className="footer-inc mx-3">© {year} Site 3 Productions</p>
        <ScrollUp/>
      </div>
    </div>
  );
}

export default HomeFooter;