import "./Contact.css";
import {Col, Form, Row} from "react-bootstrap";

const CustomForm = () => {
  return (
    <Form className="contact-form">
      <Row className="my-4">
        <Col>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text"/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>NickName (Optional)</Form.Label>
            <Form.Control type="text"/>
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <Form.Group>
            <Form.Label>Phone Number</Form.Label>
            <Form.Control type="text"/>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Email Address</Form.Label>
            <Form.Control type="text"/>
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-4">
        <Form.Group>
          <Form.Label>Tell us more about your question</Form.Label>
          <Form.Control as="textarea" rows={8}/>
        </Form.Group>
      </Row>
      <button className="submit-btn">Submit</button>
    </Form>
  );
}

const Contact = ({objRef}) => {
  return (
    <div className="contact-div" ref={objRef}>
      <h1>CONTACT US</h1>
      <div className="my-5">
        <p>Do you have any question?</p>
        <p>Feel free to contact us anytime!</p>
      </div>
      <div>
        <iframe className="responsive-iframe"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfNi187lgCOfaFvNe2P77pvX_TuMY35xUj0Cjbj8Tsl6gRfNg/viewform?embedded=true"
        >
          Loading…
        </iframe>
      </div>
      {/*<CustomForm/>*/}
    </div>
  );
}

export default Contact;