import "./Services.css";
import {Col, Row} from "react-bootstrap";

const ServiceBox = ({title, list}) => {
  return (
    <div className="service-div">
      <h3>{title}</h3>
      <ul className="mt-5">{list.map(item => <li>{item}</li>)}</ul>
    </div>
  );
}

const Services = ({objRef}) => {
  const services = [
    {
      title: "Design",
      list: ["Design Concepts", "Prototypes", "UI/UX Design", "UI/UX Production"],
    },
    {
      title: "Web Development",
      list: ["Frontend Development", "Backend Development", "System Integration", "Cloud Services"],
    },
    {
      title: "App Development",
      list: ["iOS App Development", "Android App Development"],
    },
    {
      title: "Web Hosting",
      list: ["Cloud Hosting", "Domain Registrar", "Load Balancing"],
    },
    {
      title: "Maintenance",
      list: ["App Publishing", "Testing Support", "App Maintenance", "Website Maintenance"],
    },
    {
      title: "Digital Marketing",
      list: ["SEO", "Email Marketing", "Social Media Management"],
    },
  ];

  return (
    <div className="services-div" ref={objRef}>
      <div className="services-title">Our Services</div>
      <h1 className="mb-5">WE PROVIDE</h1>
      <Row>
        {
          services.map((service, index) => (
            <Col sm={12} md={6} lg={4}>
              <ServiceBox {...service}/>
            </Col>
          ))
        }
      </Row>
    </div>
  );
}

export default Services;