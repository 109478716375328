import "./Banner.css";

const Banner = ({objRef, contactRef, onScroll}) => {
  const onClick = (e) => {
    e.preventDefault();
    onScroll(contactRef);
  }

  return (
    <div className="banner-div" ref={objRef}>
      <div className="banner-content">
        <h2>Imagine. Design. Build.</h2>
        <h1>As Easy As You Think</h1>
        <p>Got a project in your mind?</p>
        <button onClick={onClick} className="btn-touch">Get In Touch</button>
      </div>
    </div>
  );
}

export default Banner;