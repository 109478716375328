import "./About.css";

const About = ({objRef}) => {
  return (
    <div className="about-div" ref={objRef}>
      <div className="about-story-title">Our Story</div>
      <div className="about-com-title">SITE 3 PRODUCTIONS</div>
      <p className="mt-5">
        Site 3 Productions is a company with a dynamic group of
        passionate individuals committed to providing high-quality
        products and excellent services to companies in various industries.
      </p>

      <h3>OUR VISION</h3>
      <p className="mt-4">
        Our vision is to lorem ipsum dolor sit amet, consectetur
        adipiscing elit, sed do eiusmod tempor incididunt ut labore.
      </p>

      <h3>OUR MISSION</h3>
      <p className="mt-4">
        Our mission is to provide high-quality products and excellent services at affordable prices.
      </p>
    </div>
  );
}

export default About;