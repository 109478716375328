import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeNav from "./components/HomeNav/HomeNav";
import PageRoute from "./routes";
import HomeFooter from "./components/HomeFooter/HomeFooter";
import {useRef} from "react";

const App = () => {
  const scrollRef = {
    home: useRef(null),
    about: useRef(null),
    services: useRef(null),
    contact: useRef(null),
  };

  const scrollToSection = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth"
    });
  }

  return (
    <div className="App App-wrap">
      <HomeNav scrollRef={scrollRef} scrollCallback={scrollToSection}/>
      <PageRoute scrollRef={scrollRef} onScroll={scrollToSection}/>
      <HomeFooter/>
    </div>
  );
}

export default App;
