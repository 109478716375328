import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/home/home";

const PageRoute = ({scrollRef, onScroll}) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage scrollRef={scrollRef} onScroll={onScroll}/>} exact/>
      </Routes>
    </BrowserRouter>
  );
}

export default PageRoute;