import "./HomeNav.css";
import {Container} from "react-bootstrap";
import logo from "../../images/logo.png";

const NavItem = ({text, objRef, onScroll}) => {
  return (
    <div className="nav-item nav-font" onClick={() => onScroll(objRef)}>
      {text}
    </div>
  );
}

const MenuToggle = ({scrollRef, scrollCallback}) => {
  return (
    <nav role="navigation">
      <div id="menuToggle">
        <input type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu">
          <a onClick={() => scrollCallback(scrollRef.home)} href="#home"><li>Home</li></a>
          <a onClick={() => scrollCallback(scrollRef.about)} href="#about"><li>About</li></a>
          <a onClick={() => scrollCallback(scrollRef.services)} href="#services"><li>Services</li></a>
          <a onClick={() => scrollCallback(scrollRef.contact)} href="#contactus"><li>Contact Us</li></a>
        </ul>
      </div>
    </nav>
  );
}

const MobileNav = props => {
  const {scrollRef, scrollCallback} = props;
  return (
    <Container className="justify-content-between p-4 mobile-div">
      <MenuToggle {...props}/>
      <div className="nav-item p-3" onClick={() => scrollCallback(scrollRef.home)}>
        <img src={logo} alt="" width={120} height={52.5}/>
      </div>
      <div></div>
    </Container>
  );
}

const PcNav = ({scrollRef, scrollCallback}) => {
  return (
    <Container className="pc-div justify-content-between p-4">
      <div className="nav-item p-3" onClick={() => scrollCallback(scrollRef.home)}>
        <img src={logo} alt="" width={120} height={52.5}/>
      </div>

      <div className="d-flex nav-container justify-content-between p-3 mt-2">
        <NavItem text="HOME" objRef={scrollRef.home} onScroll={scrollCallback}/>
        <NavItem text="ABOUT" objRef={scrollRef.about} onScroll={scrollCallback}/>
        <NavItem text="SERVICES" objRef={scrollRef.services} onScroll={scrollCallback}/>
      </div>

      <button className="btn-contact nav-font px-3" onClick={() => scrollCallback(scrollRef.contact)}>
        CONTACT US
      </button>
    </Container>
  );
}

const HomeNav = props => {
  return (
    <>
      <PcNav {...props}/>
      <MobileNav {...props}/>
    </>
  );
}

export default HomeNav;