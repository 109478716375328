import About from "../../components/About/About";
import Services from "../../components/Services/Services";
import Banner from "../../components/Banner/Banner";
import Contact from "../../components/Contact/Contact";

const HomePage = ({scrollRef, onScroll}) => {
  return (
    <div>
      <Banner objRef={scrollRef.home} contactRef={scrollRef.contact} onScroll={onScroll}/>
      <About objRef={scrollRef.about}/>
      <Services objRef={scrollRef.services}/>
      <Contact objRef={scrollRef.contact}/>
    </div>
  );
}

export default HomePage;